@font-face {
	font-family:'poppins';
	src:url('assets/fonts/Poppins-Bold.ttf');
}

* {
  margin: 0;
}

html, body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  background: url("assets/images/bg.png");
  font-family: 'Comfortaa', cursive;
}

.App { 
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.left-reset{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.right-reset{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
}

.frame{
  width: 60vw;
  height: 40vh;
  background: url("assets/images/frame.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner{
  background: url("assets/images/grey.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  width : 97.5%;
  height: 92%;
  position: relative;
}

.block{
  width : 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 0;
  left: 0;
}

.block .seg{
  background: url("assets/images/inner.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  width : 24%;
  height: 97%;
  position: relative;
}

.jumbotron{
  width: 50vw;
  margin-top: 5vh;
  height: 30vh;
  background: url("assets/images/people.png");
  background-size: contain;
  background-repeat: no-repeat;

}

.ticker-view {
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  position: relative;
}

.number-placeholder {
  visibility: hidden;
}

.ticker-column-container {
  position: relative;
  width: 25%;
  display: flex;
  justify-content: center;
}

.ticker-digit {
  text-align: center;
}
.ticker-digit span{
  color : #0382c3;
  font-family: "poppins";
}

.ticker-column {
  position: absolute;
  height: 1000%;
  bottom: 0;
}

.ticker-digit {
  width: auto;
  height: 10%;
}

.ticker-column.increase {
  animation: pulseGreen 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

.ticker-column.decrease {
  animation: pulseRed 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

@keyframes pulseGreen {
  0%,
  100% {
    color: inherit;
  }

  50% {
    color: var(--increment-color);;
  }
}

@keyframes pulseRed {
  0%,
  100% {
    color: inherit;
  }

  50% {
    color: var(--decrement-color);
  }
}

input[type="range"] {
  margin-top: 24px;
  width: 250px;
  -webkit-appearance: none;
  height: 7px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  background-image: linear-gradient(#73d46a, #73d46a);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #73d46a;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #73d46a50 0px 0px 0px 8px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #73d46a50 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

